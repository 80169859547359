.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#nav{
  display: block;
  background: #732ffa;
  padding: 10px;
  border: none;
  display: grid;
  grid-auto-flow: column;
  justify-content: space-between;
  align-items: center;
  color:white;
  font-weight: bold;
  font-size: medium;
  height:3rem;

}
#sugg-div{
  display: grid;
  gap:1rem;
  padding-top: 4rem;
}
#sidebar{
  height: 100%;
  width: 20rem;
  background-color: #732ffa;
  position: fixed;
  z-index: 1;
  overflow: hidden;
  transition: all 1s cubic-bezier(0.075, 0.82, 0.165, 1);
  padding: 1rem;
  border-left: 2px solid white;
}

#sidebar > .cross{
  color:white;
  font-size: xx-large;
  position: absolute;
  right: 0.5rem;
  cursor: pointer;
}
.sugg-btn{
  background-color: darkorange;
  padding: 3px;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

#addNewMatrix{
  border: navajowhite;
  background: darkorange;
  color: white;
  border-radius: 10px;
  padding: 7px;
  /* right: 20px; */
  cursor: pointer;
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: center;
  font-size: medium;
  font-weight: 500;
}

.tippy-content {
  position: relative;
  padding: 0px !important;
  z-index: 1;
}


h4{
  padding: 0px;
  margin: 0px;
}
.active-tippy-option {
  background: #21262d;
  /* padding: 5px; */
  margin: 0;
}
.active-tippy-option  svg {
    stroke: #9014d3;
    stroke-width: 4px;
}